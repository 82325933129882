import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'thm-default',
    templateUrl: './thm-default.component.html',
    styleUrls: ['./thm-default.component.scss'],
})
export class ThmDefaultComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
