import { BaseSelectors } from './../state/base/base.selectors';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
    HttpXsrfTokenExtractor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import * as _ from 'lodash';

//se ne uporablja trenutno

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService {
    constructor(private injector: Injector, private tokenExtractor: HttpXsrfTokenExtractor) {}
    tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        if (this.isOneOfIgnoreredUrl(request)) {
            return next.handle(request);
        }
        const storage = this.injector.get(Store);
        const router = this.injector.get(Router);
        let token: string = <string>storage.selectSnapshot(BaseSelectors.getToken);
        return next.handle(this.addTokenToRequest(request, token, '')).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>err).status) {
                        case 0:
                            console.error('Napaka 0 - restart servisov?');
                            return throwError(err);
                        case 401:
                            //2FA
                            if (_.get(err, 'error.error_description') === '2FA') {
                                return throwError(err);
                            }
                            router.navigate(['/public/login']);
                            return throwError(err);
                        case 400:
                            return throwError(err);
                        case 403:
                            return throwError(err);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            })
        );
    }

    private isOneOfIgnoreredUrl(request: any): boolean {
        return /public/.test(request.url) || /hooks.slack.com/.test(request.url) || /token/.test(request.url);
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string | null, XSRFToken: string): HttpRequest<any> {
        let useToken: boolean = true;
        if (request.body && _.isString(request.body)) {
            if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                useToken = false;
            }
        }
        if (!token) {
            useToken = false;
        }

        if (useToken) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else {
            return request.clone();
        }
    }
}
