<!-- OUTLET -->
<div class="p-2">
    <router-outlet></router-outlet>
</div>
<!-- LOADER -->
<div class="d-flex justify-content-center loader" *ngIf="showLoader">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div data-iframe-height></div>
