import { BaseSelectors } from './../state/base/base.selectors';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private store: Store) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.store.selectSnapshot(BaseSelectors.AvailableCoverages)) {
            return true;
        }
        this.router.navigate(['/case-request/auth'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
