import { DatePickerAdapter } from './_common/adapter/date-picker-adapter';
import { TokenInterceptorService } from './_common/guards/token.interceptor.service';
import { AuthGuard } from './_common/guards/auth.guard';
import { environment } from './../environments/environment';
import { BaseState } from './_common/state/base/base.state';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import localeSl from '@angular/common/locales/sl';
import localeSlExtra from '@angular/common/locales/extra/sl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgSelectModule } from '@ng-select/ng-select';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';

registerLocaleData(localeSl, 'sl', localeSlExtra);

Bugsnag.start({
    apiKey: '42130a4514e1e912b3865bd83768d4fe',
    appVersion: environment.VERSION,
    releaseStage: environment.RELEASE_STAGE,
    enabledReleaseStages: ['production', 'staging'],
    autoTrackSessions: false,
});

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}
const dbConfig: DBConfig = {
    name: 'Prva_widget_db',
    version: 2,
    objectStoresMeta: [
        {
            store: 'ATTACHMENTS',
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'name', keypath: 'name', options: { unique: false } },
                { name: 'content', keypath: 'content', options: { unique: false } },
                { name: 'type', keypath: 'type', options: { unique: false } },
            ],
        },
    ],
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxsModule.forRoot([BaseState], {
            developmentMode: !environment?.production,
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsStoragePluginModule.forRoot({ storage: 1 }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: true }),
        NgxsFormPluginModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        NgbModule,
        NgSelectModule,
        BrowserAnimationsModule,
        RecaptchaModule,
        RecaptchaV3Module,
        NgxIndexedDBModule.forRoot(dbConfig),
        NgxMatomoRouterModule,
        NgxMatomoTrackerModule.forRoot({
            disabled: !environment.production,
            siteId: 24,
            trackerUrl: 'https://data.medifit.si/',
        })
    ],
    providers: [
        AuthGuard,
        { provide: LOCALE_ID, useValue: 'sl' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: 'sl',
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT' },
        { provide: NgbDateParserFormatter, useClass: DatePickerAdapter },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {}
