import { Coverage } from './../../model/coverage';
import { Router } from '@angular/router';
import { HelperService } from './../../service/helper.service';
import { BaseStateActions } from 'src/app/_common/state/base/base.actions';
import { Injectable } from '@angular/core';
import { BaseStateModel } from './base-state.model';
import { StateToken, State, Action, StateContext, Selector } from '@ngxs/store';
import * as moment from 'moment';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    breadcrumbs: undefined,
    selectedCoverage: undefined,
    token: undefined,
    customer: undefined,
    availableCoverages: undefined,
    uploadedDocuments: [],
    sentCaseData: undefined,
    forms: {
        insuranceCoverageForm: {
            name: 'insuranceCoverageForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        detailsHealthProblemForm: {
            name: 'detailsHealthProblemForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        refferalDocumentForm: {
            name: 'refferalDocumentForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        invoiceUploadForm: {
            name: 'invoiceUploadForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        medicalRecordForm: {
            name: 'medicalRecordForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        caseWishes: {
            name: 'caseWishes',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
        bankDetailsForm: {
            name: 'bankDetailsForm',
            defaults: {
                model: undefined,
                dirty: false,
                status: '',
                errors: {},
            },
            model: undefined,
        },
    },
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
})
@Injectable()
export class BaseState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    constructor(private helper: HelperService, private router: Router) {}

    @Action(BaseStateActions.Common.SetTokenData)
    public SetTokenData(ctx: StateContext<BaseStateModel>, { tokenData }: BaseStateActions.Common.SetTokenData) {
        ctx.patchState({
            token: tokenData,
        });
    }
    @Action(BaseStateActions.Common.SetCustomerData)
    public SetCustomerData(
        ctx: StateContext<BaseStateModel>,
        { customerData }: BaseStateActions.Common.SetCustomerData
    ) {
        ctx.patchState({
            customer: customerData,
        });
    }
    @Action(BaseStateActions.Common.SetSentCaseData)
    public SetSendCaseData(ctx: StateContext<BaseStateModel>, { caseData }: BaseStateActions.Common.SetSentCaseData) {
        ctx.patchState({
            sentCaseData: caseData,
        });
    }
    @Action(BaseStateActions.Common.SetAvailableCoverages)
    public SetAvailableCoverages(
        ctx: StateContext<BaseStateModel>,
        { customerExams }: BaseStateActions.Common.SetAvailableCoverages
    ) {
        let examsAvailableForCustomer = [];
        this.helper.coverageData.forEach((coverage: Coverage) => {
            //match coverages from helper and API on coverageSubgroup atribute
            const match = customerExams.find((el) => coverage.coverageSubgroup == el.coverageSubgroup);
            if (match) {
                const isKarenca = moment(match.coverageInholdUntil)?.isAfter(moment());
                if (!isKarenca) {
                    //remove certain steps if not Karenca
                    coverage.steps = coverage.steps.filter((step) => step.title !== 'KRITJE');
                    if (coverage.id === 6) {
                        coverage.steps = coverage.steps.filter((step) => step.title !== 'DOKUMENTI');
                    }
                }
                examsAvailableForCustomer.push({
                    ...coverage,
                    availableExams: match?.availableExams,
                    isKarenca: isKarenca,
                    coverageInholdUntil: moment(match.coverageInholdUntil)?.format('DD.MM.YYYY'),
                    title: match?.coverageFullDesc,
                    details: `Veljavno do: ${moment(match.coverageValidUntil).format('DD.MM.YYYY')}`,
                    customerName: match.name,
                    customerSurname: match.surname,
                    customerId: match.id,
                });
            }
        });

        ctx.patchState({
            availableCoverages: examsAvailableForCustomer,
        });
    }

    @Action(BaseStateActions.Common.SetBreadcrumbs)
    public SetBreadcrumbs(ctx: StateContext<BaseStateModel>, { bc }: BaseStateActions.Common.SetBreadcrumbs) {
        ctx.patchState({
            breadcrumbs: bc,
        });
    }

    @Action(BaseStateActions.Common.SetSelectedCoverage)
    public SetSelectedCoverage(
        ctx: StateContext<BaseStateModel>,
        { coverage }: BaseStateActions.Common.SetSelectedCoverage
    ) {
        const selectedCoverage = (ctx.getState()?.availableCoverages || this.helper.coverageData).find(
            (cov) => cov.id === coverage
        );
        const currentCustomerData = ctx.getState()?.customer;
        ctx.patchState({
            selectedCoverage: selectedCoverage,
            customer: {
                ...currentCustomerData,
                id: selectedCoverage.customerId,
                name: selectedCoverage.customerName,
                surname: selectedCoverage.customerSurname,
            },
            breadcrumbs: {
                step: -1,
                maxSteps: selectedCoverage?.steps?.length,
            },
        });
    }

    @Action(BaseStateActions.Common.Move)
    public Move(ctx: StateContext<BaseStateModel>, { stepCount }: BaseStateActions.Common.Move) {
        const state = ctx.getState();
        let destinationRoute = '/case-request/insurance-coverage-list';
        if (state.selectedCoverage && state.breadcrumbs) {
            const destinationIndex = state.breadcrumbs.step + (stepCount != undefined ? stepCount : 1);
            if (destinationIndex == state?.selectedCoverage?.steps?.length) {
                //konec poti -> confirmation screen
                destinationRoute = '/case-request/confirmation';
            } else if (destinationIndex > -1) {
                destinationRoute = state.selectedCoverage.steps[destinationIndex]?.route;
            }
        }
        this.router.navigate([destinationRoute]);
    }

    @Action(BaseStateActions.Common.UploadDocumentToState)
    public UploadDocumentToState(
        ctx: StateContext<BaseStateModel>,
        { docuData }: BaseStateActions.Common.UploadDocumentToState
    ) {
        const state = ctx.getState();
        let currentDocuments = [...state.uploadedDocuments];
        delete docuData.content;
        currentDocuments.unshift(docuData);

        ctx.patchState({
            ...state,
            uploadedDocuments: currentDocuments,
        });
    }

    @Action(BaseStateActions.Common.RemoveUploadedFile)
    public RemoveUploadedFile(
        ctx: StateContext<BaseStateModel>,
        { docuData }: BaseStateActions.Common.RemoveUploadedFile
    ) {
        const state = ctx.getState();
        let currentUploadedDocuments = [...state.uploadedDocuments];
        const targetIndex = currentUploadedDocuments?.findIndex((el) => el === docuData);
        if (targetIndex > -1) currentUploadedDocuments.splice(targetIndex, 1);

        ctx.patchState({
            ...state,
            uploadedDocuments: currentUploadedDocuments,
        });
    }

    @Action(BaseStateActions.Common.ClearBaseState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearBaseState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(BaseStateActions.Common.ClearCoverageData)
    public ClearCoverageData(ctx: StateContext<BaseStateModel>, {}: BaseStateActions.Common.ClearCoverageData) {
        const currentState = ctx.getState();
        ctx.patchState({
            ...DEFAULT_STATE,
            customer: currentState.customer,
            availableCoverages: currentState.availableCoverages,
        });
    }
}
