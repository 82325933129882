import { Document } from './../../model/document';
import { Coverage } from './../../model/coverage';
import { Breadcrumbs } from './../../model/breadcrumbs';
import { CaseData } from '../../model/caseData';
export namespace BaseStateActions {
    export namespace Common {
        export class SetTokenData {
            public static readonly type = '[common] SetTokenData';
            constructor(public readonly tokenData: any) {}
        }
        export class SetCustomerData {
            public static readonly type = '[common] SetCustomerData';
            constructor(public readonly customerData: any) {}
        }
        export class SetAvailableCoverages {
            public static readonly type = '[common] SetAvailableCoverages';
            constructor(public readonly customerExams: any) {}
        }
        export class SetBreadcrumbs {
            public static readonly type = '[common] SetBreadcrumbs';
            constructor(public readonly bc: Breadcrumbs) {}
        }
        export class SetSelectedCoverage {
            public static readonly type = '[common] SetSelectedCoverage';
            constructor(public readonly coverage: Coverage) {}
        }
        export class SetSentCaseData {
            public static readonly type = '[common] SetSentCaseData';
            constructor(public readonly caseData: CaseData) {}
        }
        export class Move {
            public static readonly type = '[common] Next';
            constructor(public readonly stepCount: number) {}
        }
        export class UploadDocumentToState {
            public static readonly type = '[common] UploadDocumentToState';
            constructor(public readonly docuData: Document) {}
        }
        export class RemoveUploadedFile {
            public static readonly type = '[common] RemoveUploadedFile';
            constructor(public readonly docuData: Document) {}
        }

        export class ClearBaseState {
            public static readonly type = '[common] ClearBaseState';
        }

        export class ClearCoverageData {
            public static readonly type = '[common] ClearCoverageData';
        }
    }
}
