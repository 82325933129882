import { BaseSelectors } from 'src/app/_common/state/base/base.selectors';
import { LoaderService } from './_common/service/loader.service';
import { BaseStateActions } from './_common/state/base/base.actions';
import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'prva-prijava-skode';
    public showLoader: boolean;
    constructor(private router: Router, private store: Store, private loader: LoaderService) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const selectedCoverage = this.store.selectSnapshot(BaseSelectors.SelectedCoverage);
                const customerData = this.store.selectSnapshot(BaseSelectors.getCustomerData);
                const currentStep = selectedCoverage?.steps?.find((comp) => comp?.route == val?.urlAfterRedirects);
                if (!(selectedCoverage && currentStep) && customerData) {
                    //breadcrumbs not defined -> hide
                    this.store.dispatch(new BaseStateActions.Common.SetBreadcrumbs(undefined));
                } else if (selectedCoverage && currentStep && customerData) {
                    //all data -> update breadcrubms
                    this.store.dispatch(
                        new BaseStateActions.Common.SetBreadcrumbs({
                            step: selectedCoverage.steps.findIndex((el) => el == currentStep),
                            maxSteps: selectedCoverage?.steps?.length,
                            title: currentStep?.title,
                            detail: selectedCoverage?.title,
                        })
                    );
                } else {
                    //not logged in -> redirect to login page
                    this.store.dispatch(new BaseStateActions.Common.Move(-100));
                }
            }
        });
        loader.isLoading$.pipe(debounceTime(100)).subscribe((showLoader) => (this.showLoader = showLoader));
    }

    ngOnInit() {}
}
