import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Coverage } from '../model/coverage';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor() {}
    public maxNumOfAttachments: number = 10;
    public maxSizeOfCompressedAttachment: number = 2.5; 
    public allowedAttachmentsTypes: string[] = ['pdf','jpg','png','jpeg'];
    public maxWidthOrHeight = 1600; //compression settings

    public getPrvaUrl(): string {
        return environment.prva.protocol + environment.prva.rootUrl + environment.prva.port + environment.prva.url;
    }

    public coverageData: Coverage[] = [
        {
            id: 1,
            coverageSubgroup: 'SPEC-STD',
            title: 'Specialisti standard',
            icon: 'la-hospital',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
                {
                    route: '/case-request/crq-case-wishes',
                    title: 'IZVAJALEC',
                },
            ],
        },
        {
            id: 2,
            coverageSubgroup: 'SPEC-NSTD',
            title: 'Specialisti nadstandard',
            icon: 'la-hospital-alt',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
                {
                    route: '/case-request/crq-case-wishes',
                    title: 'IZVAJALEC',
                },
            ],
        },
        {
            id: 3,
            coverageSubgroup: 'POSEGI',
            title: 'Operativni posegi',
            icon: 'la-x-ray',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/crq-medical-record',
                    title: 'KARTON',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
                {
                    route: '/case-request/crq-case-wishes',
                    title: 'IZVAJALEC',
                },
            ],
        },
        {
            id: 4,
            coverageSubgroup: 'REHAB',
            title: 'Rehabilitacije',
            icon: 'la-wheelchair',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
                {
                    route: '/case-request/crq-case-wishes',
                    title: 'IZVAJALEC',
                },
            ],
        },
        {
            id: 5,
            coverageSubgroup: 'ZDR',
            title: 'Zdravila',
            icon: 'la-pills',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/crq-invoice-upload',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
                {
                    route: '/case-request/crq-bank-details',
                    title: 'PODATKI',
                },
            ],
        },
        {
            id: 6,
            coverageSubgroup: 'DZM',
            title: 'Drugo mnenje',
            icon: 'la-stethoscope',
            steps: [
                {
                    route: '/case-request/coverage',
                    title: 'KRITJE',
                },
                {
                    route: '/case-request/crq-referral-document',
                    title: 'DOKUMENTI',
                },
                {
                    route: '/case-request/details-health-problems',
                    title: 'OPIS',
                },
            ],
        },
    ];
    public createDateAsUTC(date) {
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            )
        );
    }
    public countryCodes = [
        { code: 'AD', name: 'Andora' },
        { code: 'AE', name: 'Združeni Arabski Emirati' },
        { code: 'AF', name: 'Afganistan' },
        { code: 'AG', name: 'Antigva In Barbuda' },
        { code: 'AI', name: 'Angvila' },
        { code: 'AL', name: 'Albanija' },
        { code: 'AM', name: 'Armenija' },
        { code: 'AN', name: 'Nizozemski Antili' },
        { code: 'AO', name: 'Angola' },
        { code: 'AQ', name: 'Antarktika' },
        { code: 'AR', name: 'Argentina' },
        { code: 'AS', name: 'Ameriška Samoa' },
        { code: 'AT', name: 'Avstrija' },
        { code: 'AU', name: 'Avstralija' },
        { code: 'AW', name: 'Aruba' },
        { code: 'AZ', name: 'Azerbajdžan' },
        { code: 'BA', name: 'Bosna In Hercegovina' },
        { code: 'BB', name: 'Barbados' },
        { code: 'BD', name: 'Bangladeš' },
        { code: 'BE', name: 'Belgija' },
        { code: 'BF', name: 'Burkina Faso' },
        { code: 'BG', name: 'Bolgarija' },
        { code: 'BH', name: 'Bahrajn' },
        { code: 'BI', name: 'Burundi' },
        { code: 'BJ', name: 'Benin' },
        { code: 'BM', name: 'Bermudi' },
        { code: 'BN', name: 'Brunej' },
        { code: 'BO', name: 'Bolivija' },
        { code: 'BR', name: 'Brazilija' },
        { code: 'BS', name: 'Bahami' },
        { code: 'BT', name: 'Butan' },
        { code: 'BV', name: 'Bouvetov Otok' },
        { code: 'BW', name: 'Bocvana' },
        { code: 'BY', name: 'Belorusija' },
        { code: 'BZ', name: 'Belize' },
        { code: 'CA', name: 'Kanada' },
        { code: 'CC', name: 'Kokosovi (Keeling) Otoki' },
        { code: 'CD', name: 'Kongo, Demokratična Republika' },
        { code: 'CF', name: 'Srednjeafriška Republika' },
        { code: 'CG', name: 'Kongo' },
        { code: 'CH', name: 'Švica' },
        { code: 'CI', name: 'Slonokoščena Obala' },
        { code: 'CK', name: 'Cookovi Otoki' },
        { code: 'CL', name: 'Čile' },
        { code: 'CM', name: 'Kamerun' },
        { code: 'CN', name: 'Kitajska' },
        { code: 'CO', name: 'Kolumbija' },
        { code: 'CR', name: 'Kostarika' },
        { code: 'CU', name: 'Kuba' },
        { code: 'CV', name: 'Zelenortski Otoki' },
        { code: 'CX', name: 'Božični Otok' },
        { code: 'CY', name: 'Ciper' },
        { code: 'CZ', name: 'Češka Republika' },
        { code: 'DE', name: 'Nemčija' },
        { code: 'DJ', name: 'Džibuti' },
        { code: 'DK', name: 'Danska' },
        { code: 'DM', name: 'Dominika' },
        { code: 'DO', name: 'Dominikanska Republika' },
        { code: 'DZ', name: 'Alžirija' },
        { code: 'EC', name: 'Ekvador' },
        { code: 'EE', name: 'Estonija' },
        { code: 'EG', name: 'Egipt' },
        { code: 'ER', name: 'Eritreja' },
        { code: 'ES', name: 'Španija' },
        { code: 'ET', name: 'Etiopija' },
        { code: 'FI', name: 'Finska' },
        { code: 'FJ', name: 'Fidži' },
        { code: 'FK', name: 'Falklandski Otoki (Malvini)' },
        { code: 'FM', name: 'Mikronezija (Federat. Države)' },
        { code: 'FO', name: 'Ferski Otoki' },
        { code: 'FR', name: 'Francija' },
        { code: 'GA', name: 'Gabon' },
        { code: 'GB', name: 'Združeno Kraljestvo' },
        { code: 'GD', name: 'Grenada' },
        { code: 'GE', name: 'Gruzija' },
        { code: 'GH', name: 'Gana' },
        { code: 'GI', name: 'Gibraltar' },
        { code: 'GL', name: 'Grenlandija' },
        { code: 'GM', name: 'Gambija' },
        { code: 'GN', name: 'Gvineja' },
        { code: 'GQ', name: 'Ekvatorialna Gvineja' },
        { code: 'GR', name: 'Grčija' },
        { code: 'GS', name: 'J.Georgija In Otoki J.Sandwich' },
        { code: 'GT', name: 'Gvatemala' },
        { code: 'GU', name: 'Guam' },
        { code: 'GW', name: 'Gvineja Bissau' },
        { code: 'GY', name: 'Gvajana' },
        { code: 'HK', name: 'Hongkong' },
        { code: 'HM', name: 'Heardov Otok In Mcdonald.Otoki' },
        { code: 'HN', name: 'Honduras' },
        { code: 'HR', name: 'Hrvaška' },
        { code: 'HT', name: 'Haiti' },
        { code: 'HU', name: 'Madžarska' },
        { code: 'ID', name: 'Indonezija' },
        { code: 'IE', name: 'Irska' },
        { code: 'IL', name: 'Izrael' },
        { code: 'IN', name: 'Indija' },
        { code: 'IO', name: 'Brit.Ozemlje Ind.Oceana' },
        { code: 'IQ', name: 'Irak' },
        { code: 'IR', name: 'Iran (Islamska Republika)' },
        { code: 'IS', name: 'Islandija' },
        { code: 'IT', name: 'Italija' },
        { code: 'JM', name: 'Jamajka' },
        { code: 'JO', name: 'Jordanija' },
        { code: 'JP', name: 'Japonska' },
        { code: 'KE', name: 'Kenija' },
        { code: 'KG', name: 'Kirgizistan' },
        { code: 'KH', name: 'Kambodža' },
        { code: 'KI', name: 'Kiribati' },
        { code: 'KM', name: 'Komori' },
        { code: 'KN', name: 'Saint Kitts In Nevis' },
        { code: 'KP', name: 'Koreja, Dem. Ljudska Rep.' },
        { code: 'KR', name: 'Koreja, Republika' },
        { code: 'KW', name: 'Kuvajt' },
        { code: 'KY', name: 'Kajmanski Otoki' },
        { code: 'KZ', name: 'Kazahstan' },
        { code: 'LA', name: 'Laos' },
        { code: 'LB', name: 'Libanon' },
        { code: 'LC', name: 'Saint Lucia' },
        { code: 'LI', name: 'Lihtenštajn' },
        { code: 'LK', name: 'Šrilanka' },
        { code: 'LR', name: 'Liberija' },
        { code: 'LS', name: 'Lesoto' },
        { code: 'LT', name: 'Litva' },
        { code: 'LU', name: 'Luksemburg' },
        { code: 'LV', name: 'Latvija' },
        { code: 'LY', name: 'Libijska Arab. Džamahirija' },
        { code: 'MA', name: 'Maroko' },
        { code: 'MD', name: 'Moldavija, Republika' },
        { code: 'ME', name: 'Črna Gora' },
        { code: 'MG', name: 'Madagaskar' },
        { code: 'MH', name: 'Marshallovi Otoki' },
        { code: 'MK', name: 'Makedonija, Republika' },
        { code: 'ML', name: 'Mali' },
        { code: 'MM', name: 'Mjanmar' },
        { code: 'MN', name: 'Mongolija' },
        { code: 'MO', name: 'Macao' },
        { code: 'MP', name: 'Severni Marianski Otoki' },
        { code: 'MR', name: 'Mavretanija' },
        { code: 'MS', name: 'Montserrat' },
        { code: 'MT', name: 'Malta' },
        { code: 'MU', name: 'Mauritius' },
        { code: 'MV', name: 'Maldivi' },
        { code: 'MW', name: 'Malavi' },
        { code: 'MX', name: 'Mehika' },
        { code: 'MY', name: 'Malezija' },
        { code: 'MZ', name: 'Mozambik' },
        { code: 'NA', name: 'Namibija' },
        { code: 'NC', name: 'Nova Kaledonija' },
        { code: 'NE', name: 'Niger' },
        { code: 'NF', name: 'Norfolški Otok' },
        { code: 'NG', name: 'Nigerija' },
        { code: 'NI', name: 'Nikaragva' },
        { code: 'NL', name: 'Nizozemska' },
        { code: 'NO', name: 'Norveška' },
        { code: 'NP', name: 'Nepal' },
        { code: 'NR', name: 'Nauru' },
        { code: 'NU', name: 'Niue' },
        { code: 'NZ', name: 'Nova Zelandija' },
        { code: 'OM', name: 'Oman' },
        { code: 'PA', name: 'Panama' },
        { code: 'PE', name: 'Peru' },
        { code: 'PF', name: 'Francoska Polinezija' },
        { code: 'PG', name: 'Papua Nova Gvineja' },
        { code: 'PH', name: 'Filipini' },
        { code: 'PK', name: 'Pakistan' },
        { code: 'PL', name: 'Poljska' },
        { code: 'PM', name: 'Saint Pierre In Miquelon' },
        { code: 'PN', name: 'Pitcairn' },
        { code: 'PS', name: 'Palestinsko Ozemlje, Zasedeno' },
        { code: 'PT', name: 'Portugalska' },
        { code: 'PW', name: 'Palau' },
        { code: 'PY', name: 'Paragvaj' },
        { code: 'QA', name: 'Katar' },
        { code: 'RO', name: 'Romunija' },
        { code: 'RU', name: 'Ruska Federacija' },
        { code: 'RW', name: 'Ruanda' },
        { code: 'SA', name: 'Saudova Arabija' },
        { code: 'SB', name: 'Salomonovi Otoki' },
        { code: 'SC', name: 'Sejšeli' },
        { code: 'SD', name: 'Sudan' },
        { code: 'SE', name: 'Švedska' },
        { code: 'SG', name: 'Singapur' },
        { code: 'SH', name: 'Saint Helena' },
        { code: 'SI', name: 'Slovenija' },
        { code: 'SK', name: 'Slovaška' },
        { code: 'SL', name: 'Sierra Leone' },
        { code: 'SM', name: 'San Marino' },
        { code: 'SN', name: 'Senegal' },
        { code: 'SO', name: 'Somalija' },
        { code: 'SR', name: 'Surinam' },
        { code: 'ST', name: 'Sao Tome In Principe' },
        { code: 'SV', name: 'Salvador' },
        { code: 'SY', name: 'Sirska Arabska Republika' },
        { code: 'SZ', name: 'Svazi' },
        { code: 'TC', name: 'Otoki Turks In Caicos' },
        { code: 'TD', name: 'Čad' },
        { code: 'TF', name: 'Francosko Južno Ozemlje' },
        { code: 'TG', name: 'Togo' },
        { code: 'TH', name: 'Tajska' },
        { code: 'TJ', name: 'Tadžikistan' },
        { code: 'TK', name: 'Tokelau' },
        { code: 'TL', name: 'Timor-Leste' },
        { code: 'TM', name: 'Turkmenistan' },
        { code: 'TN', name: 'Tunizija' },
        { code: 'TO', name: 'Tonga' },
        { code: 'TR', name: 'Turčija' },
        { code: 'TT', name: 'Trinidad In Tobago' },
        { code: 'TV', name: 'Tuvalu' },
        { code: 'TW', name: 'Tajvan, Provinca Kitajske' },
        { code: 'TZ', name: 'Tanzanija, Združena Rep.' },
        { code: 'UA', name: 'Ukrajina' },
        { code: 'UG', name: 'Uganda' },
        { code: 'UM', name: 'Stranski Zunanji Otoki Zdr.Drž.' },
        { code: 'US', name: 'Združene Države' },
        { code: 'UY', name: 'Urugvaj' },
        { code: 'UZ', name: 'Uzbekistan' },
        { code: 'VA', name: 'Sveti Sedež (Vatikan. M. Drž.)' },
        { code: 'VC', name: 'Saint Vincent In Grenadine' },
        { code: 'VE', name: 'Venezuela' },
        { code: 'VG', name: 'Deviški Otoki (Britanski)' },
        { code: 'VI', name: 'Deviški Otoki (Zda)' },
        { code: 'VN', name: 'Vietnam' },
        { code: 'VU', name: 'Vanuatu' },
        { code: 'WF', name: 'Otoki Wallis In Futuna' },
        { code: 'WS', name: 'Samoa' },
        { code: 'YE', name: 'Jemen' },
        { code: 'YT', name: 'Mayotte' },
        { code: 'ZA', name: 'Južna Afrika' },
        { code: 'ZM', name: 'Zambija' },
        { code: 'ZW', name: 'Zimbabve' },
        { code: 'XC', name: 'Ceuta' },
        { code: 'XK', name: 'Kosovo' },
        { code: 'XL', name: 'Melilla' },
        { code: 'XS', name: 'Srbija' },
    ];
}
