import { CaseData } from './../../model/caseData';
import { Breadcrumbs } from './../../model/breadcrumbs';
import { BaseStateModel } from './base-state.model';
import { BaseState } from './base.state';
import { Selector } from '@ngxs/store';
import { Coverage } from '../../model/coverage';
import { Document } from '../../model/document';
export class BaseSelectors {
    @Selector([BaseState])
    public static getState(state: BaseStateModel): BaseStateModel | any {
        return state;
    }
    @Selector([BaseState])
    public static getToken(state: BaseStateModel): any {
        return state.token;
    }
    @Selector([BaseState])
    public static getCustomerData(state: BaseStateModel): {
        name: string;
        surname: string;
        birthDate?: string;
        pin?: string;
        id: number;
    } {
        return state.customer;
    }
    @Selector([BaseState])
    public static getSentCaseData(state: BaseStateModel): CaseData {
        return state.sentCaseData;
    }
    @Selector([BaseState])
    public static getBreadcrumbs(state: BaseStateModel): Breadcrumbs {
        return state.breadcrumbs;
    }
    @Selector([BaseState])
    public static SelectedCoverage(state: BaseStateModel): Coverage {
        return state.selectedCoverage;
    }
    @Selector([BaseState])
    public static AvailableCoverages(state: BaseStateModel): Coverage[] {
        return state.availableCoverages;
    }
    @Selector([BaseState])
    public static UploadedDocuments(state: BaseStateModel): Document[] {
        return state.uploadedDocuments;
    }
    @Selector([BaseState])
    public static UploadedExams(state: BaseStateModel): Document[] {
        return state.uploadedDocuments?.filter((el) => el.type == 'DOC-EXAM');
    }
    @Selector([BaseState])
    public static UploadedOrders(state: BaseStateModel): Document[] {
        return state.uploadedDocuments?.filter((el) => el.type == 'DOC-ORDER');
    }
    @Selector([BaseState])
    public static UploadedInvoices(state: BaseStateModel): Document[] {
        return state.uploadedDocuments?.filter((el) => el.type == 'INVOICE');
    }
}
