import { ThmDefaultComponent } from './_theme/thm-default/thm-default.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

const routes: Routes = [
    {
        path: '',
        component: ThmDefaultComponent,
        children: [
            {
                path: 'case-request',
                loadChildren: () =>
                    import('./_modules/case-request/case-request.module').then((m) => m.CaseRequestModule),
            },
            { path: '**', redirectTo: 'case-request/auth' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), HttpClientModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
